import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';



const useStyles=makeStyles((theme)=>({
    
    mainname:{
        [theme.breakpoints.down('lg')]: {
     fontSize:45
    },
        [theme.breakpoints.down('sm')]: {
     fontSize:30
    }
    },
    mainwrite:{
         [theme.breakpoints.down('lg')]: {
     fontSize:20
    },
        [theme.breakpoints.down('sm')]: {
     fontSize:14
    }
    },

    root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
    imageList: {
    width: 500,
    height: 450,
  },
  
    icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundImage: `url(${Image})`,
        backgroundSize:'contain',
        backgroundPosition:'center', 
         textAlign:'left',
        paddingLeft:'10%',
        [theme.breakpoints.up('xs')]: {
        height:240,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:560,
          paddingTop: '15%'
         
    }
    }
}));


export default function AlertDialog(props) {
  const classes = useStyles();
  const info = props.data
  const trackingnumber = props.track

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  var data = 0

 const sumation = (value) => {
      
      data +=value
      return value

  };

  return (
    <div>
      <Button onClick={handleClickOpen} aria-label="delete" style={{height:'55px', width:'100%', backgroundColor:'#3B4954', color:'white' }}>
      Receipt
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
        Receipt
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         
            
        <div class="center-align">
            
      
         <img src="" width="16%" alt="" />
         <br/>
         <span><b>Accra, Taifa Taxi Station</b></span><br/>
         <span><b>+233 509766847</b></span><br/>
         <span style={{fontSize:"23px"}}><b>Payment Receipt</b></span>
         </div>
         <br/>
         
         <Grid container>

          <Grid xs={4}>
          <h3>Bill To</h3>
          <p>{info[0].firstname + " " + info[0].othername + " " + info[0].lastname}</p>
          <p>{info[0].address} </p>
          <p>{info[0].contact}</p>
          </Grid>

          <Grid xs={4}>
          <h3>Bill To</h3>
          <p>John Kofi</p>
          <p>plot 24 block 3, kumasi</p>
          <p>0243344343</p>
          </Grid>

          <Grid xs={4}>
          <b>Date:</b> date here <br/>
          <b>Receipt Number:</b> date here <br/>
          <b>Tracking Number:</b> date here <br/>

        
          </Grid>

          

         </Grid>

         <Divider/>
         
          <TableContainer >
                                                                    <Table className={classes.table} aria-label="simple table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell></TableCell>
                                                                                <TableCell align="center">Item</TableCell>
                                                                                <TableCell align="center">CBM</TableCell>
                                                                                <TableCell align="center">Quantity</TableCell>
                                                                                <TableCell align="center">Unit Price</TableCell>
                                                                                <TableCell align="center">Total Price</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {(info[0].shipment.filter(bug => bug.tracking_number === trackingnumber))[0].items.length !== 0 ?
                                                                                (info[0].shipment.filter(bug => bug.tracking_number === trackingnumber))[0].items.map((row, index) => (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell align="center">
                                                                                            {index + 1}
                                                                                        </TableCell>
                                                                                        <TableCell align="center">
                                                                                            {row.item}
                                                                                        </TableCell>

                                                                                   

                                                                                        <TableCell align="center">
                                                                                            {row.cbm}
                                                                                        </TableCell>


                                                                                        <TableCell align="center">

                                                                                            {row.quantity}

                                                                                        </TableCell>
                                                                                        <TableCell align="center">

                                                                                            {row.unitprice}

                                                                                        </TableCell>

                                                                                        <TableCell align="center">
                                                                                            {row.total}
                                                                                        </TableCell>
                                                                                        <Divider/>
                                                                                        <b>Total: {sumation(row.total)}</b> 
                                                                                    </TableRow>))
                                                                                         

                                                                                          
                                                                                         
                                                                                :

                                                                                <h1 align="center">No Data</h1>
                                                                            }

                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>






          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Download
          </Button>
          <Button onClick={handleClose} color="primary">
            Print
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
