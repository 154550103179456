import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';


import School from '@material-ui/icons/School';
import Work from '@material-ui/icons/Work';
import Bookmark from '@material-ui/icons/Bookmark';
import MovieFilter from '@material-ui/icons/MovieFilter';
import Code from '@material-ui/icons/Code';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AOS from 'aos';
import "aos/dist/aos.css";


export default function CustomizedTimeline() {
    
     AOS.init({
    // initialise with other settings
    duration : 700
  });

  return (
      <Container maxWidth="md" >
    <Timeline align="left">
      <TimelineItem style={{width:'198%',marginLeft:'-98%'}} data-aos="fade-up">
      
        <TimelineSeparator >
          <TimelineDot color='Primary'>
            <School />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{textAlign:"left"}}>
      
            <Typography variant="h6" style={{marginLeft:"0px"}} component="h2" data-aos="fade-up">
             <b> EDUCATION </b>
            </Typography>
      <br/>
            <Typography>
     
      
      
      <span style={{fontSize:"16px"}} data-aos="fade-up"> KWAME NKRUMAH UNIVERSITY OF SCIENCE AND TECHNOLOGY (KNUST) </span> <br/>
      <span style={{fontSize:"14px", color:"grey"}} data-aos="fade-up"> BSc. Computational Physics </span> <br/>
      <span style={{fontSize:"14px", color:"grey"}} data-aos="fade-up"> September 2015 - June 2019 </span>
      
      
     
      </Typography>
         
        </TimelineContent>
      </TimelineItem>
      

<br/>
<TimelineItem style={{width:'198%',marginLeft:'-98%'}} data-aos="fade-up">
      
        <TimelineSeparator >
          <TimelineDot color='Primary'>
            <Work />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{textAlign:"left"}}>
      
            <Typography variant="h6" style={{marginLeft:"0px"}} component="h2" data-aos="fade-up">
             <b> WORK EXPERIENCE </b>
            </Typography>
<br/>
            <Typography>
     
      <span style={{fontSize:"16px"}} data-aos="fade-up"> RAVINLEAD TECH HUB (RTH) </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">Founder & Full-stack web developer </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> September 2020 - Date </span>
      
     <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up"> KNUST - COLLEGE OF ENGINEERING </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> IT / Student affairs Officer (National Service) </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> September 2019 - September 2020 </span>
      
        
     <br/>
     <br/> 
          
    <span style={{fontSize:"16px"}} data-aos="fade-up"> OPANET TECHNOLOGIES  </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> Backend Developer (Internship) </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> June 2018 - August 20218 </span>
      
        
     <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">UNIQUE ACADEMY </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> IT Teacher </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> January 2014 - August 2015 </span>
      
      </Typography>
         
        </TimelineContent>
      </TimelineItem>
      


<br/>
<TimelineItem style={{width:'198%',marginLeft:'-98%'}} data-aos="fade-up">
      
        <TimelineSeparator >
          <TimelineDot color='Primary'>
            <Bookmark />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{textAlign:"left"}}>
      
            <Typography variant="h6" style={{marginLeft:"0px"}} component="h2" data-aos="fade-up">
             <b> PROJECTS </b>
            </Typography>
<br/>
            <Typography>
     
      <span style={{fontSize:"16px"}} data-aos="fade-up">Project for Ravinlead Tech Hub (Feb 2022 – Date), I am currently working as a full-stack
Developer to build a school database management system (SIS Revolution) for teachers and 
administration to facilitate their work. Technologies used were ReactJS, NodeJS, MongoDB, 
Redux, Python, OpenCV, Socket.Io, and integrated payment system from Paystack.  </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> <a href="https://sisrevolution.com">https://sisrevolution.com</a> </span>
           <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  </span>
      <br/>
      <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">Contract (Sep. 2022 – Oct. 2022), I worked as a fullstack Developer to build a hotel website with booking and live chat features, and a hotel administrative system for Cromer Apartments. Technologies used were ReactJS, NodeJS, MongoDB, Redux, Socket Io, and integrated payment system from DPO.  </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> <a href="https://cromerapartments.com">https://cromerapartments.com</a> </span>
           <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  </span>
      
     <br/>
     <br/>   
     <span style={{fontSize:"16px"}} data-aos="fade-up">Contract (May 2022 – July 2022), I worked as a fullstack Developer to build a web and mobile applications for an Insurance Brokerage company called AY Consult Limited. This software helped to manage insurance policies by agents and administrators. Technologies used were ReactJS, NodeJS, MongoDB, Redux.  </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> <a href="https://app.ayconsultgh.com">https://app.ayconsultgh.com</a> </span>
           <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  </span>
      
     <br/>
     <br/> 
     <span style={{fontSize:"16px"}} data-aos="fade-up">Contract (Dec. 2021 – Feb 2022), I worked as a lead developer to build an online shop for China Wholesale Group Buying. Technologies used were HTML, CSS, ReactJS, NodeJS, MongoDB, I deployed API from paystack to accept payment on the system, it has over 500 users.   </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> <a href="https://chinawholesalebuying.com">https://chinawholesalebuying.com</a> </span>
           <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  </span>
      
     <br/>
     <br/> 
      <span style={{fontSize:"16px"}} data-aos="fade-up">Voluteer (Dec 2020), Created and managed a website for USA based 3AM Mission Ministry and deployed PayPal API for donation. Technologies used were HTML, CSS, JavaScript, Bootstrap, MySQL and PHP.  </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> <a href="https://www.3ammissionminstry.com">https://3ammissionministry.com</a> </span>
           <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  </span>
      
     <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">Contract (June 2021), Developed and managed a website for JSK Renewable energy. Technologies used were HTML, CSS, ReactJS. </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">https://sisrevolution.com/jskrenewable</span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> </span>
      
        
     <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">Contract (June 2020 – August 2020), Worked as a full-stack developer to build a website for ZF1 Garden Logistics with and exclusive feature that helps clients to track their shipment. Technologies used were HTML, CSS, ReactJS, NodeJS, MongoDB, I deployed API from Mnotify to send instant SMS notification to clients. </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> https://sisrevolution.com/zf1logistics </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  </span>

       <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">Contract (June 2020 – August 2020), Worked as a full-stack developer to build a database management system for ZF1 garden logistics to manage records of clients, shipments and send instant SMS. Technologies used were HTML, CSS, ReactJS, NodeJS, MongoDB, I deployed API from Mnotify to send instant SMS notification to clients </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  </span>
      
    <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">Worked as a frontend developer to build a website for Heralds world Ministries Inc. Technologies used were HTML, CSS, ReactJS, PHP, MySQL, I deployed API from Spreaker.com to add podcast audio to website  </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  </span>
       <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">Personal Project (Sept. 2020), Worked as a full-stack developer to build website for Ravinlead Tech Hub. Technologies used were HTML, CSS, Bootstrap, JavaScript, MySQL and PHP </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  https://ravinlead.com </span> <br/>
      <span/>   <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">Worked as a frontend developer and deployed API from Opanet technologies to build an application called Water Bank for help in water delivery and order placements for students in hostels on KNUST campus. Technologies used were HTML, CSS, javascript, API from Opanet technologies </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">   </span> <br/>
      <span/>   <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">Design and simulate the characteristics of various transmission Cables (Python). </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  Final year Project </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> 2018/2019 </span>
      <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">The SIR model for spread of disease (Python, shell scripting) </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  Personal Project </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> 2018/2019 </span>
      <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">Planar three body problem (Python). </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  Personal Project </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> 2018 </span>
            <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">Two-body problem in general relativity; the black hole and the sun (Python). </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  Personal Project </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> 2018 </span>
             <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">Finding gravitational force between two objects (C++) </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">  Personal Project </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> 2018 </span>
      
      </Typography>
         
        </TimelineContent>
      </TimelineItem>
      






<br/>
<TimelineItem style={{width:'198%',marginLeft:'-98%'}} data-aos="fade-up">
      
        <TimelineSeparator >
          <TimelineDot color='Primary'>
            <MovieFilter />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{textAlign:"left"}}>
      
            <Typography variant="h6" style={{marginLeft:"0px"}} component="h2" data-aos="fade-up">
             <b> PERSONAL EXPOSURE </b>
            </Typography>
<br/>
            <Typography>
     
                   
    
      <span style={{fontSize:"16px"}} data-aos="fade-up">Class representative </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> SDA Senior High School, Bekwai</span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> September 2011 to May 2013 </span>
      <br/>
     <br/>
                 
      <span style={{fontSize:"16px"}} data-aos="fade-up">Establishment of the Ravinlead Tech Hub (RTH) that functiona as both learning and profit avenue</span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> <a href="https://www.ravinlead.com">Ravinlead.com</a> </span>
           <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> January 2019 - Date </span>
      
     <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up"> Successful completion of a final year project work on the topic "Design and simulate the characteristics of various transmission Cables", by using python programming language I built a computer simulation to determine the characteristics of selected transmission cables after practically determining the resistivity of the cables.</span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> Final year project.</span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> 2018 / 2019 </span>
      
        
     <br/>
     <br/>
      <span style={{fontSize:"16px"}} data-aos="fade-up">I stood in the stead of my boss (The principal Administrative Officer, CoE, Student Affairs, KNUST) as the secretary to the college internship committee and college industrial meeting held on Thursday 24th October, 2019 at the associated consultants conference room, KNUST to discuss on how to improve on the students internship mobile and web application. </span> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> October 2019 </span>

   
      </Typography>
         
        </TimelineContent>
      </TimelineItem>
      









<br/>
<TimelineItem style={{width:'198%',marginLeft:'-98%'}} data-aos="fade-up">
      
        <TimelineSeparator >
          <TimelineDot color='Primary'>
            <Code />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{textAlign:"left"}}>
      
            <Typography variant="h6" style={{marginLeft:"0px"}} component="h2" data-aos="fade-up">
             <b>TECHNICAL SKILLS </b>
            </Typography>
<br/>
            <Typography>
     
                   
    
      <b style={{fontSize:"16px"}} data-aos="fade-up">PROGRAMMING LANGUAGES </b> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> Python (7 years), JavaScript (7 years), C++ (7 years), HTML / CSS (7 years), JSON (7 years), AJAX (7 years), Bash (6 years), Java (6 years),  NodeJS (6 years), PHP (6 years) </span> 
        
      
     <br/>
     <br/>
     
      <b style={{fontSize:"16px"}} data-aos="fade-up">FRAMEWORKS</b> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> React JS (6 years), Express JS (6 years), Socket IO (4 years)</span> 
     <br/>
     <br/>
     <b style={{fontSize:"16px"}} data-aos="fade-up">DevOps</b> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up">Google Cloud (GCP), Linux, Windows</span> 
     
     <br/><br/>
     <b style={{fontSize:"16px"}} data-aos="fade-up">SOFTWARE</b> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> Git, Github MySQL, MongoDB, Redux, REST, OpenCV, FastAPI
 </span>
     <br/><br/>
     <b style={{fontSize:"16px"}} data-aos="fade-up">Computer Software / Operating System</b> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> MS Word, PowerPoint, Adobe XD, Figma, Adobe Photoshop, Latex, Excel, Linux, Windows.    
 </span> 
     <br/>
     <br/><br/>
     <b style={{fontSize:"16px"}} data-aos="fade-up">OTHERS</b> <br/>
      <span style={{fontSize:"14px", color:"gray"}} data-aos="fade-up"> Sound engineering, research, computer hardware, communication, self-motivation, leadership, time management, teamwork, adaptability, conflict resolution, and creativity.      
 </span> 
     <br/>
     
      </Typography>
         
        </TimelineContent>
      </TimelineItem>
      






    </Timeline>
      </Container>
  );
}
