import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LocationOn from '@material-ui/icons/LocationOn';
import  {
  BrowserRouter as Link
} from 'react-router-dom';

const useStyles=makeStyles((theme)=>({
    caro:{ 
        
      backgroundColor:'black',
      color:'white',
        padding:'50px',
       
    },
    back:{ 
        
      backgroundColor:'black',
      color:'gray',
        padding:'10px',
        fontSize:13
       
    }
}));




function Example(props){
    const classes=useStyles()
    return (
        
        <div className={classes.back}>
        <div className={classes.caro}>
        <Grid container spacing={8}>
        <Grid item lg={3} sm={12} md={3} >
         <h2 style={{textAlign:'left'}} data-aos={"fade-up"}> ZF1 Garden Logistics</h2>
        <Grid container spacing={0} style={{textAlign:'left'}} data-aos={"fade-up"}>
        
       
ZF1 Garden Logistics is a full-service logistics services provider with our offices in Ghana/China. We work with clients in Ghana and abroad to support their domestic and international logistics needs via road, air, rail, and sea transport.
        </Grid>
        </Grid>
        
       
        <Grid item  lg={2} sm={12} md={2} >
    <h2 style={{textAlign:'left'}} data-aos={"fade-up"}> Quick Link</h2>
        
        
        <Grid container spacing={1} style={{textAlign:'center'}}>
        
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
                                <a href='./sea'>
        Sea Shipping
       </a>
         </Grid>
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
                                <a href='./air'>
        Air Shipping
       </a>
         </Grid>
        
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
                                <a href='./Warehousing'>
        Warehousing
       </a>
         </Grid>
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
                                <a href='./about'>
        About Us
       </a>
                                </Grid>
                            
        </Grid>
   
        </Grid>
        
         <Grid item  lg={4} sm={12} md={4} >
         <h2 style={{textAlign:'left'}} data-aos={"fade-up"}> Location</h2>
        <Grid container spacing={2}>
        
        <Grid item lg={1} sm={2} md={2} style={{textAlign:'left', paddingTop:'8px'}} data-aos={"fade-up"}>
        <LocationOn/>
         </Grid>
        <Grid item lg={11} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <span> China Air Cargo Warehouse Address
China, Guangzhou</span>        
         </Grid>

<Grid item lg={1} sm={2} md={2} style={{textAlign:'left', paddingTop:'8px'}} data-aos={"fade-up"}>
        <LocationOn/>
         </Grid>
        <Grid item lg={11} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <span> GHANA, GREATER ACCRA OFFICE
Taifa-Accra</span>        
         </Grid>
<Grid item lg={1} sm={2} md={2} style={{textAlign:'left', paddingTop:'8px'}} data-aos={"fade-up"}>
        <LocationOn/>
         </Grid>
        <Grid item lg={11} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <a href="https://goo.gl/maps/kuEJyQHjEerrqNYR7"> GHANA, ASHANTI REGION OFFICE
Asafo-Kumasi</a>        
         </Grid>
<Grid item lg={1} sm={2} md={2} style={{textAlign:'left', paddingTop:'8px'}} data-aos={"fade-up"}>
        <LocationOn/>
         </Grid>
        <Grid item lg={11} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <span> GHANA, BONO REGION OFFICE
Airport Residential-Sunyani</span>        
         </Grid>
        </Grid>
        
        </Grid>
        
        <Grid item  lg={3} sm={12} md={3} >
        <h2 style={{textAlign:'left'}} data-aos={"fade-up"}> Contact Us</h2>
        
        <Grid container spacing={2} style={{textAlign:'left'}}>
         
        
          <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
            CHINA <br/>
        <a href='tel:+861 8460 0729 40'>+861 8460 0729 40 </a><br/>
       
       
         </Grid>
  <Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}} data-aos={"fade-up"}>
        
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
            GHANA <br/>
       <a href='tel:+233 302 441 765'>+233 302 441 765 </a><br/>
                                <a href='tel:+233 559 266 471'>+233 559 266 471</a><br/>
                                <a href='tel:+233 059 411 8347'>+233 594 118 347</a><br/>
         </Grid>
        
        
      
        
        
        </Grid>
    
        </Grid>
        
        

      </Grid>
 </div>
     <p style={{textAlign:'center'}}>
      Copyright 2021 ZF1 Garden Logistics all rights reserved <br/>
      Powered by <a  href="https://ravinlead.com">RavinLead Tech Hub</a>
</p>
        </div>
        
   
        
        
    

    )
}


export default Example


