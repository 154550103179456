import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Call from '@material-ui/icons/Call';
import Email from '@material-ui/icons/Email';
import LocationOn from '@material-ui/icons/LocationOn';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Para1 from './img/atiefoo/woman-wearing-headphones-as-call-center-staff-with-hand-open.jpg';
import Leave from './leave'
import AOS from 'aos';
import "aos/dist/aos.css";



const useStyles=makeStyles((theme)=>({
    mainname:{
        [theme.breakpoints.down('lg')]: {
     fontSize:40
    },
        [theme.breakpoints.down('sm')]: {
     fontSize:30
    }
    },
    mainwrite:{
         [theme.breakpoints.down('lg')]: {
     fontSize:19
    },
        [theme.breakpoints.down('sm')]: {
     fontSize:14
    }
    },
 
     root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '70%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundImage: `url(${Image})`,
        backgroundSize:'cover',
        backgroundPosition:'center',
        textAlign:'left',
        paddingLeft:'8%',
        [theme.breakpoints.up('xs')]: {
        height:150,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:550,
          paddingTop: '15%'
         
    }
    }
}));





function Example(props){
      AOS.init({
    // initialise with other settings
    duration : 700
  });
//    const classes = useStyles();
    
    var items = [
        {
            name: "",
            image: Para1,
            description: ""
        }
    ]


    return (
        <div>
        <Carousel swipe={true} touch={true} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>

    
 
            <h2 className="MuiTypography-h4"  style={{marginTop:40}} data-aos={"fade-up"}>Get In Touch</h2>
        <p className="MuiTypography-h6" style={{marginTop:'-29px'}}></p>
<br/>
<br/>



<iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d7940.750796853124!2d-0.254333!3d5.658722!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sgh!4v1634895602748!5m2!1sen!2sgh" width="100%" height="400" style={{border:0, allowfullscreen:""}} title="location"loading="lazy"></iframe>



<br/>
<br/>

        <Grid container spacing={0} >
        <Grid item xs={12} sm={6} md={6}  >

        <h2 className="MuiTypography-h4" style={{marginTop:'20px',textAlign:'center'}} data-aos={"fade-up"}>Our Contact</h2>

           <Grid item  xs={12} sm={12} md={12} >
        
        <Grid container spacing={1} style={{textAlign:'center'}}>
         

    <Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}} data-aos={"fade-up"}>
        <Call/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
            CHINA <br/>
        <a href='tel:+861 8460 0729 40'>+861 8460 0729 40 </a><br/>
       
         </Grid>
  <Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}} data-aos={"fade-up"}>
        <Call/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
            GHANA <br/>
        <a href='tel:+233 302 441 765'>+233 302 441 765 </a><br/>
                                <a href='tel:+233 559 266 471' > +233 559 266 471 </a><br/>
        
         </Grid>

<Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}}data-aos={"fade-up"}>
        <Email/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}}data-aos={"fade-up"}>
        <a href='mailto:support@ZF1logistics.com'>support@ZF1logistics.com</a>
         </Grid>


<Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}} data-aos={"fade-up"}>
        <WhatsApp/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
                                <a href="https://wa.me/+233594118347/?text=Hi there, Welcome to ZF1 Garden Logistics. Please send your message"> WhatsApp Chat</a>
         </Grid>

<Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}} data-aos={"fade-up"}>
        <LocationOn/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <span>China, No. 798, Linanjiao Road ,Tangge Vilage, Shijing Town, Baiyun District, Guangzhou</span>        
         </Grid>

<Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}} data-aos={"fade-up"}>
        <LocationOn/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <span> Accra, Taifa Taxi Station, Behind Pentecost Church</span>        
         </Grid>
<Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}} data-aos={"fade-up"}>
        <LocationOn/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <span> Kumasi, Asafo, Near SSNIT</span>        
         </Grid>


        </Grid>
    
        </Grid>
        
        
         </Grid> 

        <Grid item xs={12} sm={6} md={6} style={{textAlign:'left', padding:15}}data-aos={"fade-up"}>

        <h3 className="MuiTypography-h4" style={{marginTop:'10px',textAlign:'left'}} data-aos={"fade-up"}>Leave A Message</h3>
        <p className="MuiTypography-h6" style={{marginTop:'-29px'}}></p>
<br/>
            <p style={{marginBottom:80}}>
           <Leave/>
            </p>
            
            
            
         </Grid>
        </Grid>
            <br/>
            <br/>
            <br/>
            <br/>



    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
    return (
        
        <div className={classes.caro} style={{backgroundImage: `url(${props.item.image})`}}>
            <h1 style={{color:"black",  marginTop:"0%", width:"300px" }} className={classes.mainname} data-aos={"flip-right"}><span>{props.item.name} </span></h1>
            <p style={{ color:"black", marginTop:"-20px", width:"240px"}} className={classes.mainwrite} data-aos={"zoom-in"}>{props.item.description}</p>

          
        
        
        </div>
        
     
     

    )
}


export default Example


