
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import HomeWork from '@material-ui/icons/HomeWork';
import LocationCity from '@material-ui/icons/LocationCity';
import Telegram from '@material-ui/icons/Telegram';
import MeetingRoom from '@material-ui/icons/MeetingRoom';
import House from '@material-ui/icons/House';
import HowToReg from '@material-ui/icons/HowToReg';
import Home from '@material-ui/icons/Home';
import Apartment from '@material-ui/icons/Apartment';
import StepConnector from '@material-ui/core/StepConnector';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
    
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
      1: <HomeWork />,
      2: <Telegram/>,
      3: <House />,
      4: <MeetingRoom />,
      5: <Apartment />,
      6: <LocationCity />,
      7: <Home />,
      8: <HowToReg />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


function getSteps() {
    return ['Select campaign settings', 'Create an ad group', 'Create an ad'];
}





export default function CustomizedSteppers(props) {
    
  const classes = useStyles();
    let num = 0
    
    if(props.data[props.data.length-1] === "Delivered"){
        num=props.data.length
    }
    else if(props.data[props.data.length-1 ]=== "Arrived"){
        num=props.data.length-1
    }else{
         num=props.data.length-1
    }
    
  const activeStep = React.useState(num);
 
function getSteps() {
    if(props.data[props.data.length-1] === "Delivered"){
         return [...props.data];
    }
    else if(props.data[props.data.length-1 ] === "Arrived"){
        return [...props.data,  'Delivered'];
    }else{
         return [...props.data, "Arrived", 'Delivered'];
    }
    
 
    }
    const steps = getSteps();
    console.log(steps)
    console.log(activeStep)
  return (
      <div className={classes.root}>
         
          <Stepper alternativeLabel activeStep={activeStep[0]} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                  <Step key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
              ))}
          </Stepper>

        
      </div>
  );
}




