import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Para1 from './img/atiefoo/terms.jpg';
import AOS from 'aos';
import "aos/dist/aos.css";
import "./index.css";



const useStyles=makeStyles((theme)=>({
    mainname:{
        [theme.breakpoints.down('lg')]: {
     fontSize:50
    },
        [theme.breakpoints.down('sm')]: {
     fontSize:30,
            color:'white'
    }
    },
    mainwrite:{
         [theme.breakpoints.down('lg')]: {
     fontSize:20
    },
        [theme.breakpoints.down('sm')]: {
     fontSize:14
    }
    },
    
     large:{
        
       [theme.breakpoints.up('xs')]: {
        width: theme.spacing(26),
        height: theme.spacing(26),
           marginLeft:'18%',
        },
       [theme.breakpoints.up('sm')]: {
        width: theme.spacing(26),
        height: theme.spacing(26),
           marginLeft:'15%',
        }
   
  },
 
     root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '70%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundImage: `url(${Image})`,
        backgroundSize:'cover',
        backgroundPosition:'center',
         textAlign:'left',
        paddingLeft:'10%',

        [theme.breakpoints.up('xs')]: {
        height:150,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:450,
          paddingTop: '15%'
         
    }
    }
}));





function Example(props){
      AOS.init({
    // initialise with other settings
    duration : 700
  });
      
   var items = [
        {
            name: "",
            image: Para1,
            description: ""
        }
    ]

    return (
        <div>
        <Carousel swipe={true} touch={true} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>

    <br/>
    <br/>    

 
 <Container  maxWidth="md"> 
 <div style={{textAlign:"left"}}>
     
     <h5 className="center-align"><b>Terms and Conditions</b></h5>
     
     
     <b>ZF1 GARDEN LOGISTICS</b> is a legally operating shipping and logistics company. ZF1 agrees to provide shipping and logistics services to the customer, following the terms and conditions stated in this binding document. 
     <br/> 
     <br/> 
<b>IN CONSIDERATION</b> OF the mutual benefits set forth in this agreement, the parties to this agreement agree on the points as follows: 
 <br/>
 <br/>
<b>Note:</b> <br/> You must read, agree to, and accept all of the terms and conditions contained in this document before doing business with us. You are bound by these terms, and by continuing to use our services, you are indicating your acceptance of these terms
     
     <br/>
     <br/>
     <b>Charges and Rates</b>
     <br/>
     
      <ol>
          <li>
              ZF1 does not have permanent standard rates/charges for shipping services. Prices vary per given times and are quoted considering all container and freight charges, logistics, ports and operational charges.
          </li>
          <li>
              At every given time, we advise clients to confirm current rates from our official website, social media handles or offices before sending goods to our warehouse in China. Contrary to this, we will assume that client agrees and consent to the price being charged per the given time. Hence, when the items arrive in Ghana, price will not be subject to discount reviews.
          </li>
          <li>
              The cost of air cargo is determined by its kilogram (KG) weight.
          </li>
          <li>
              Charges for sea cargo is dependent on the dimension or CBM of item. (Small boxes are charged based on the weight and item value upon arrival).
          </li>
          <li>
              Heavy goods, objects, equipment, machineries and the likes attract additional charges based on their weight, volume and item value. 
          </li>
          
      </ol> 
      <br/>
      
      <b>Custom and Clearing</b>
      <ol>
          <li>
              When goods arrive in Ghana, all duties and custom procedures will be handled by ZF1.
          </li>
          <li>
              Items take 7 to 10 days for them to be cleared.
          </li>
      </ol>
      
      <br/>
      
      <b>Warehousing</b>
      <ol>
          <li>
              Items will be kept in the warehouse for up to 5 days upon arrival.
          </li>
          <li>
              After 5 days, there is a penalty for items remaining in the warehouse. 
          </li>
      </ol>
      <br/>
      
      <b>Date of loading</b>
      <ol>
          <li>
              The loading date is not the same as the departure date. 
          </li>
          <li>
              The vessel departs 10 to 12 days following the loading date. 
          </li>
      </ol> <br/>
      
      <b>Others</b>
      <ol>
          <li>
              If there is any damage to goods when they arrive at our ware house in China, ZF1 is not responsible for paying damages 
          </li>
          <li>
              Only defective goods officially procured by ZF1 from our verified suppliers will be replaced. 
          </li>
          <li>In cases of procurement, if the quality of items not purchased from ZF1 suppliers does not match the criteria, ZF1 cannot be held liable.</li>
          <li>If products are damaged upon arrival in Ghana, resulting from mishandling or negligence on the part of ZF1, we will pay half of the duty cost and one-third of the product cost. The following information should be provided by owners of damaged goods:
          
          <ol>
              <li>Proof of payment from supplier of the item </li>
              <li>Any important information for claims</li>
          </ol>
          
          </li>
          <li>If clients agree to purchase an item after receiving audio and visual confirmation (quality assurance), ZF1 will not be held liable</li>
          <li>We advise clients to ensure that electronic and fragile items are well package by their suppliers before sending them to our warehouse. ZF1 will not be financially responsible for client negligence. </li>
          <li>Before sending fragile things to our warehouse, they should be appropriately wrapped. If your property is damaged, ZF1 assumes no responsibility. (Breakables)</li>
          <li>Before picking up items from the Ghanaian warehouse, they should be thoroughly scrutinized.</li>
          <li>ZF1 is not responsible for the amount or quality of items not procured by ZF1's supplier.</li>
          <li>Before sending to the Chinese warehouse, double-check quantity and quality. </li>
          <li>Any illegal item (battery goods) sent to our warehouse without stating the contents of the cargo will be confiscated, for example, no battery, liquid, or powder goods supplied by air.</li>
          <li>ZF1 assumes no responsibility for products sent by air (delay and damages)</li>
          <li>Because ZF1 does not inspect the contents of any commodities sent to the warehouse, failure to declare items is prohibited.</li>
          <li>ZF1 will not issue refunds for things that the client has agreed to buy. </li>
      </ol>
      <br/>
       <b>Packing List for Goods</b>
    
       <div>
           All items sent to the warehouse should be accompanied by a packing list and a copy sent to us. Every packing list requested for should contain the following:
           <div className="row">
           <ol>
           <div className="col l4 m6 s12">
               
                   <li>HS code</li>
                   <li>Unit</li>
                   <li>Gross weight</li>
              
           </div>
           <div className="col l4 m6 s12">
              
                   <li>Product description</li>
                   <li>Total Package</li>
                   <li>Size/Dimension</li>
              
           </div>
           <div className="col l4 m6 s12">
              
                   <li>Quantity</li>
                   <li>Net weight </li>
                   <li>Volume/CBM</li>
              
           </div>
            </ol>
           </div>
       </div>
       <span>Below is an example of a packing list for reference. </span>
     <br/>
     <br/>
     <br/>
     
 </div>

</Container>
 <div style={{padding:"10px"}}>
     <table className="centered table-responsive" >
         <thead>
            <tr>
                <th>Marks No</th>
                <th>Item No</th>
                <th>HS Code</th>
                <th>Product description</th>
                <th>Quantity</th>
                <th>Unit</th>
                <th>Total Package</th>
                <th>Net weight kgs</th>
                <th>Gross weight kgs</th>
                <th>Size MM</th>
                <th>Volume/CBM</th>
            </tr>
         </thead>
         
         <tbody>
             <tr>
                 <td>JASON MACHINE <br/>
                    TO: Life in the garden <br/>
                    PACKAGING MACHINE <br/>
                    MADE IN CHINA
                    <br/>
                    <br/>
                    Lewis Amponsah RUTH, KL 798
                     
                 </td>
                 
                 <td>JS-22D</td>
                 <td>8422303090</td>
                 <td>PACKAGING MACHINE</td>
                 <td>1</td>
                 <td>SET</td>
                 <td>1</td>
                  <td>300</td>
                  <td>350</td>
                  <td>(L)1200*(W)900*(H)1940</td>
                   <td>2.1</td>
             </tr>
             <tr>
                 
             </tr>
              <td colspan="11"></td>
              <tr>
                  <td colspan="4">
                      Total
                  </td>
                  <td>1</td>
                  <td></td>
                  <td>1</td>
                  <td>300</td>
                  <td>350</td>
                  <td></td>
                  <td>2.1</td>
              </tr>
           
            
         </tbody>
     </table>
     
 </div>
 
  
 
  
            


    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
   return (
        
                   
        <div className={classes.caro} style={{backgroundImage: `url(${props.item.image})`}}>
            <h1 className={classes.mainname} style={{color:"black",  marginTop:"25%", width:"300px" }} data-aos={"flip-right"}><span style={{ paddingRight:12}}>{props.item.name} </span></h1>
            <p style={{color:"black"}} className={classes.mainwrite} data-aos={"zoom-in"}>{props.item.description}</p>

          
        
        
        </div>
        
    

    )
}


export default Example


