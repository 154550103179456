import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles} from '@material-ui/core/styles';
import Para1 from './img/atiefoo/7.jpg';
import nodata from './img/atiefoo/nodata.svg';
import Imager from './img/atiefoo/close-up-calculator-business-working-desk-dark-background.jpg';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';
import Printreceipt from "./receipt"


import AOS from 'aos';
import "aos/dist/aos.css";
//import itemData from './data';
//import ImageList from '@material-ui/core/ImageList';
//import ImageListItem from '@material-ui/core/ImageListItem';
import Status from './status';


const useStyles=makeStyles((theme)=>({
    
    mainname:{
        [theme.breakpoints.down('lg')]: {
     fontSize:45
    },
        [theme.breakpoints.down('sm')]: {
     fontSize:30
    }
    },
    mainwrite:{
         [theme.breakpoints.down('lg')]: {
     fontSize:20
    },
        [theme.breakpoints.down('sm')]: {
     fontSize:14
    }
    },

    root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
    imageList: {
    width: 500,
    height: 450,
  },
  
    icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundImage: `url(${Image})`,
        backgroundSize:'contain',
        backgroundPosition:'center', 
         textAlign:'left',
        paddingLeft:'10%',
        [theme.breakpoints.up('xs')]: {
        height:240,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:560,
          paddingTop: '15%'
         
    }
    }
}));



function Example() {
    AOS.init({
        // initialise with other settings
        duration: 700
    });
    //    const classes = useStyles();

    var items = [
        {
            name: "Stay updated!",
            image: Para1,
            description: "Get timely updates about the status of your items"
        }
    ]

    const classes = useStyles();
    const [info, setInfo] = React.useState(0)
    const [open, setOpen] = React.useState(false);
    const [trackingnumber, setTrackingnumber] = React.useState("");

    const handleClickOpen = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(!open);
        setInfo(0)
    
    };

    const tracker = () => {

        const track = async () => {
            setTrackingnumber(document.getElementById("tracking").value)
            await axios.post("https://server.zf1logistics.com/api/auth/track/",
                {

                    tracking_number: document.getElementById("tracking").value,

                }).then(res => { console.log(res); if (res.status === 200) { setInfo(res.data) } else { setInfo([]) } })

        }
        track()
        handleClickOpen()
    }

   var topay=0
   var paid=0
   var cbm=0


    return (
        
        <div>
        
            {info!==0 && info.length!==0?(info[0].shipment.filter(bug => bug.tracking_number === trackingnumber))[0].items.map((data, index) =>
             {return ( topay += data.total) }
               
            )
                :
                ""
            } 
            {info !== 0 && info.length !== 0?(info[0].shipment.filter(bug => bug.tracking_number === trackingnumber))[0].items.map((data, index) =>
            { return( cbm += parseFloat(data.cbm)*data.quantity )}
               
            )
                :
                ""
            }
            {info !== 0 && info.length !== 0?(info[0].shipment.filter(bug => bug.tracking_number === trackingnumber))[0].payment.map((data, index) =>
            { return( paid += parseFloat(data.value))}
               
            )
                :
                ""
            }

         <Carousel swipe={true} touch={true} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
        <br/>
        <br/>

            <Container maxWidth="md">

                <h1>
                <b>Track your product to know its progress</b>
                </h1>
                



 <br/>
      <br/>
      
        <Grid container spacing={0} >
     
<Grid item lg={7} sm={8} md={7} style={{backgroundColor:"", color:"white", textAlign:"left"}}>

           
        <TextField
          id="tracking"
          label="Tracking Number"
          placeholder="Tracking Number"
          multiline
          type="text"
          variant="outlined"
          style={{width:"100%", borderRadius:"0%"}}
        />
         </Grid> 


  
        <Grid item lg={5} sm={3} md={5} style={{textAlign:'left'}}>
                       

                            <div>
                            <Button onClick={() => tracker()} style={{ height: '55px', width: '100%', backgroundColor: '#FB4E02', color: 'white' }}>
                                    TRACK SHIPMENT
                                </Button>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                fullWidth="true"
                                maxWidth="md"
                                >
                                <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                                { info!==0 ?
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">

                                            {info.length !== 0 ?
                                                <Container maxWidth='lg' >


                                                    

                                                    <List style={{ textAlign: 'left', padding: 10 }}>


                                                        <h3>
                                                            Tracking Number : {trackingnumber}
                                                    </h3>
                                                        <div style={{ width: '100%' }}>
                                                            <ListItemText primary={info[0].firstname + " " + info[0].othername + " " + info[0].lastname} secondary="Receiver's Name" data-aos="fade-up" />
                                                        </div>
                                                        <Divider data-aos="fade-up">
                                                        </Divider>
                                                        <div style={{ width: '100%' }}>
                                                            <ListItemText primary={info[0].contact} secondary="Receiver's Contact" data-aos="fade-up" />
                                                        </div>
                                                        <Divider data-aos="fade-up">
                                                        </Divider>
                                                        <div style={{ width: '100%' }}>
                                                            <ListItemText primary={info[0].address} secondary="Receiver's Address" data-aos="fade-up" />
                                                        </div>
                                                        <Divider data-aos="fade-up">
                                                        </Divider>
                                                        <div style={{ width: '100%' }}>
                                                            <ListItemText primary={"$ " + (topay).toFixed(3)} secondary="Amount to Pay" data-aos="fade-up" />
                                                        </div>
                                                        <Divider data-aos="fade-up">
                                                        </Divider>
                                                        <div style={{ width: '100%' }}>
                                                            <ListItemText primary={cbm} secondary="Total CBM" data-aos="fade-up" />
                                                        </div>
                                                        <Divider data-aos="fade-up">
                                                        </Divider>


                                                        <div style={{ width: '100%' }}>
                                                            <ListItemText primary={"$ " + paid} secondary="Amount Paid" data-aos="fade-up" />
                                                        </div>
 <Divider data-aos="fade-up">
                                                        </Divider>


                                                        <div style={{ width: '100%' }}>
                                                            <ListItemText primary={"$ " + topay - paid} secondary="Arrears" data-aos="fade-up" />
                                                        </div>

                                                        <br />

                                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                                            <ListItemText primary="" secondary="Shipment Status" />

                                                        </div>

                                                        <Status data-aos="fade-up" data={(info[0].shipment.filter(bug => bug.tracking_number === trackingnumber))[0].status} />


                                                        <br />


                                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                                            <ListItemText primary="" secondary="Items" />






                                                            {
                                                                <TableContainer component={Paper}>
                                                                    <Table className={classes.table} aria-label="simple table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>No</TableCell>
                                                                                <TableCell align="center">Item</TableCell>
                                                                                <TableCell align="center">China Tracking Num.</TableCell>
                                                                                <TableCell align="center">CBM</TableCell>
                                                                                <TableCell align="center">Quantity</TableCell>
                                                                                <TableCell align="center">Unit Price</TableCell>
                                                                                <TableCell align="center">Total Price</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {(info[0].shipment.filter(bug => bug.tracking_number === trackingnumber))[0].items.length !== 0 ?
                                                                                (info[0].shipment.filter(bug => bug.tracking_number === trackingnumber))[0].items.map((row, index) => (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell align="center">
                                                                                            {index + 1}
                                                                                        </TableCell>
                                                                                        <TableCell align="center">
                                                                                            {row.item}
                                                                                        </TableCell>

                                                                                        <TableCell align="center">
                                                                                            {row.china}
                                                                                        </TableCell>

                                                                                        <TableCell align="center">
                                                                                            {row.cbm}
                                                                                        </TableCell>


                                                                                        <TableCell align="center">

                                                                                            {row.quantity}

                                                                                        </TableCell>
                                                                                        <TableCell align="center">

                                                                                            {row.unitprice}

                                                                                        </TableCell>

                                                                                        <TableCell align="center">
                                                                                            {row.total}
                                                                                        </TableCell>

                                                                                    </TableRow>))

                                                                                :
                                                                                <img src={nodata} alt="no data" width="20%" />
                                                                            }
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>





                                                            }





<Printreceipt data={info} track={trackingnumber}/>

                                                        </div>
                                                    </List>





                                                </Container>
                                                :
                                                <div style={{ textAlign: "center" }}>
                                                    <h4>
                                                      Tracking number not found
                                                    </h4>
                                                    <Container width="sm">
                                                        <Skeleton variant="text" />
                                                        <Skeleton variant="text" />
                                                        <Skeleton variant="text" />
                                                    </Container>
                                                </div>
                                            }


                                        </DialogContentText>
                                    </DialogContent>

                                    :
                                    <Container width="sm"> <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </Container>

                                }
                                <DialogActions>

                                        <Button onClick={handleClose} color="primary" autoFocus>
                                            Ok
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>

                       
            
         </Grid>


        </Grid>


          
<br/>
<br/>
<br/>
<br/>
<br/>



            </Container>
            <img src={Imager} alt="no data"  width='100%' height='100%'/>
       </div>
        
        
   
      
    )
}

function Item(props)



{
    const classes=useStyles()
    return (
        
                 
        <div className={classes.caro} style={{backgroundImage: `url(${props.item.image})`}}>
                  
        </div>
        
        

    )
}


export default Example


