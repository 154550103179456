import Image1 from './img/use2.jpg'
import Image2 from './img/baking.jpg'
import Image3 from './img/chemical.jpg'
import Image4 from './img/house.jpg'

let data=[
    {
    img: Image1,
    title: "Biomass Burner"
}, {
    img: Image2,
    title: "Hot Stove"
}, {
    img: Image3,
    title: "Chemical Plant"
}, {
    img:Image4,
    title: "House Warming"
}
               ]

export default data