import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles } from '@material-ui/core/styles';
import Power from './img/atiefoo/img1.jpg';
import Storage from './img/atiefoo/img4.jpg';
import Warehouse from './img/atiefoo/img3.jpg';
import Payment from './img/atiefoo/img6.jpg';
import Ship from './img/atiefoo/aerial-view-container-cargo-ship-sea1.jpg';
import Grid from '@material-ui/core/Grid';
import Para1 from './img/atiefoo/Services.jpg';
import AOS from 'aos';
import  {
  BrowserRouter as 
  Link
} from 'react-router-dom';
import Button from '@material-ui/core/Button';

import "aos/dist/aos.css";


const useStyles = makeStyles((theme) => ({
    
    mainname:{
        [theme.breakpoints.down('lg')]: {
     fontSize:40,
            marginTop:"17%"
    },
        [theme.breakpoints.down('sm')]: {
     fontSize:28,
           marginTop:"29%"
    }
    },
    mainwrite:{
         [theme.breakpoints.down('lg')]: {
     fontSize:20
    },
        [theme.breakpoints.down('sm')]: {
     fontSize:14
    }
    },

     root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '70%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundImage: `url(${Image})`,
        backgroundSize:'contain',
        backgroundPosition:'center',
        textAlign:'left',
        paddingLeft:'10%',
        [theme.breakpoints.up('xs')]: {
        height:160,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:560,
          paddingTop: '15%'
         
    }
    }
}));





function Example(props)

{
     AOS.init({
    // initialise with other settings
    duration : 700
  });
//    const classes = useStyles();
    
  var items = [
        {
            name: "",
            image: Para1,
            description: ""
        }
    ]

    return (
        <div>
        <Carousel swipe={true} touch={true} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>


<div style={{marginTop:"-32px", zIndex:'9'}}>    
 

    
    
    
        <Grid container spacing={0} >
      
<Grid item xs={12} sm={5} md={5} style={{backgroundColor:"#FB4E02", color:"white", textAlign:"left"}}>

          <h1  style={{marginTop:"20%",padding:20}} data-aos="fade-up">Air Shipping
</h1>
            <p style={{padding:20,marginTop:'-29px', fontSize:'20px'}} data-aos="fade-up">
            We'll deliver your goods by air and you will receive your goods within 6 to 14 days after shipment
<br/>
<br/>
                            <a href="./air">
    <Button style={{backgroundColor:'#1C73D5', color:'white'}}>
    Read More
    </Button>
</a>
            </p>


         </Grid> 


  
        <Grid item xs={12} sm={7} md={7} style={{textAlign:'left'}}>

                        <img src={Power} alt="no data"   width='100%' height='100%'/>
            
         </Grid>


        </Grid>



        <Grid container spacing={0} >
      

        <Grid item xs={12} sm={7} md={7} style={{textAlign:'left'}}>

                        <img src={Ship} alt="no data"  width='100%' height='100%'/>
            
         </Grid>



<Grid item xs={12} sm={5} md={5} style={{marginTop:'0px',textAlign:'left', backgroundColor:'#1C73D5', color:'white'}}>

           <h1  style={{marginTop:"20%",padding:20}} data-aos="fade-up">Sea Shipping

</h1>
            <p style={{padding:20,marginTop:'-29px', fontSize:'20px'}} data-aos="fade-up">
           We'll deliver your goods by sea and you will receive your goods within 40 to 55  days after shipment
<br/>
<br/>
                            <a href="./sea">
    <Button style={{backgroundColor:'#FB4E02', color:'white'}}>
    Read More
    </Button>
</a>
            </p>
         </Grid> 


        </Grid>









    
        <Grid container spacing={0} >
      
<Grid item xs={12} sm={5} md={5} style={{backgroundColor:"#FB4E02", color:"white", textAlign:"left"}}>

          <h1  style={{marginTop:"20%",padding:20}} data-aos="fade-up">Warehousing
</h1>
            <p style={{padding:20,marginTop:'-29px', fontSize:'20px'}} data-aos="fade-up">
           We receive and deliver your goods into our state of the art warehouse. You are assured of the safety of your goods
<br/>
<br/>
                            <a href="./warehouse">
    <Button style={{backgroundColor:'#1C73D5', color:'white'}}>
    Read More
    </Button>
</a>
            </p>
         </Grid> 


  
        <Grid item xs={12} sm={7} md={7} style={{textAlign:'left'}}>

                        <img src={Warehouse} alt="no data"   width='100%' height='100%'/>
            
         </Grid>


        </Grid>






  <Grid container spacing={0} >
      

        <Grid item xs={12} sm={7} md={7} style={{textAlign:'left'}}>

                        <img src={Storage} alt="no data"  width='100%' height='100%'/>
            
         </Grid>



<Grid item xs={12} sm={5} md={5} style={{marginTop:'0px',textAlign:'left', backgroundColor:'#1C73D5', color:'white'}}>

           <h1  style={{marginTop:"20%",padding:20}} data-aos="fade-up">Storage


</h1>
            <p style={{padding:20,marginTop:'-29px', fontSize:'20px'}} data-aos="fade-up">
           We ensure all your goods are safe with us throughout the shipping process  and the storage in our various warehouse centers.
<br/>
<br/>
                            <a href="./warehouse">
    <Button style={{backgroundColor:'#FB4E02', color:'white'}}>
    Read More
    </Button>
</a>
            </p>
         </Grid> 


        </Grid>





  <Grid container spacing={0} >
      
<Grid item xs={12} sm={5} md={5} style={{backgroundColor:"#FB4E02", color:"white", textAlign:"left"}}>

          <h1  style={{marginTop:"15%",padding:20}} data-aos="fade-up">Secure Payment

</h1>
            <p style={{padding:20,marginTop:'-29px', fontSize:'20px'}} data-aos="fade-up">
           Don’t let payment, bank charges or transfer limit obstruct your business with Chinese suppliers. We can assist you to make instant payments to your Chinese suppliers whiles you pay us in cedis. Whatever the amount, we offer the best rates and lowest charges.
<br/>
<br/>
                            <a href="./payment">
    <Button style={{backgroundColor:'#1C73D5', color:'white'}}>
    Read More
    </Button>
</a>
            </p>
         </Grid> 


  
        <Grid item xs={12} sm={7} md={7} style={{textAlign:'left'}}>

                        <img src={Payment} alt="no data"   width='100%' height='100%'/>
            
         </Grid>


        </Grid>








    </div>
    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
     return (
         
         
        <div className={classes.caro} style={{backgroundImage: `url(${props.item.image})`}}>
            <h1 className={classes.mainname} style={{color:"white", width:"350px" }} data-aos={"flip-right"}><span style={{ paddingRight:12}}>{props.item.name} </span></h1>
            <p style={{color:"white"}} className={classes.mainwrite} data-aos={"zoom-in"}>{props.item.description}</p>

          
        
        
        </div>
        
        

    )
}


export default Example


